@import "main";

.title {
  font-weight: bolder;
}

.btn {
  background-color: rgba(0, 0, 0, 0);
  transition: all 0.3s;
  width: 13em;
  height: 3em;
  line-height: 2.7em;
  cursor: pointer;
  box-shadow: 0 0 0 rgba(0, 0, 0, 0.1), inset 0 0 0 rgba(0, 0, 0, 0.1);
  color: black;
  border: 0.13em solid #a82c89;
  border-radius: 0;
  font-size: 1.5em;
  font-weight: bolder;
}

.btn:hover {
  box-shadow: 0 0 2em rgba(0, 0, 0, 0.1), inset 0 0 1em rgba(0, 0, 0, 0.1);
}

.btn.running {
  background-color: #a82c89;
  border-color: #a82c89;
  color: white;
}

#speedtest {
  .testName {
    font-weight: bold;
  }

  #dlText {
    color: #a22c87;
  }

  #ulText {
    color: #533089;
  }

  #pingText {
    color: #194798;
  }

  #jitText {
    color: #194798;
  }

  .meterText {
    margin-top: 10px;
  }

  .testArea {
    padding: 50px 5px;
    position: relative;

    & > div {
      position: relative;
      z-index: 5;
    }

    &:after {
      $height: 50px;
      content: "";
      display: block;
      width: calc(100% + 4px);
      height: $height;
      background-color: white;
      position: absolute;
      top: calc(50% - ($height / 2));
      left: -2px;
      transition: transform .3s;
    }

    .svg {
      display: block;
      height: 100%;
      width: 100%;
      position: absolute;
      left: 0;
      top: 0;
      transition: fill .3s;

      path {
        stroke: #ffffff;
        fill: #e2e2e2;
      }
    }

    .svg-1 path {
      stroke: #ffffff;
      fill: #e2e2e2;
    }

    .svg-2 {
      opacity: 0;
      transition: opacity .3s;

      path {
        stroke: #ffffff;
        stroke-width: 1;
        fill: url(#gradient-2);
      }
    }

    &.active {
      &:after {
        -ms-transform: scaleY(0);
        transform: scaleY(0);
      }

      .svg-2 {
        opacity: 1;
      }
    }
  }

}
