@import "normalize";
/*! Main Styles / Created by Artemkin Vitaly [vitaldev.ru] */
html, body {
  color: #202020;
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-size: 14px;
  text-align: center;
}

.container {
  width: 100%;
  max-width: 940px;
  padding: 0 15px;
  margin: 0 auto 30px;
}

.title {
  color: #404040;
  font-size: 1.8rem;
  font-weight: 500;
}

.subtitle {
  margin-bottom: 25px;
}

p {
  font-weight: 500;
  margin: 0 0 3px;
}

#information {
  font-size: 1.15rem;
  border-bottom: 1px solid #e5e5e5;

  #userip {
    color: #555;
    text-decoration: none;

    &:hover {
      color: #555;
      text-decoration: none;
    }
  }

  p:last-of-type {
    margin-bottom: 30px;
    margin-left: 25px;
  }
}

#speedtest {
  border-bottom: 1px solid #e5e5e5;

  .testGroup {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }

  .testArea {
    flex-basis: calc(100% / 4 - 20px);
    position: relative;
    top: 1em;
    margin: 0 10px 15px;
    @media all and (max-width: 680px) {
      //flex-basis: calc(100% / 2 - 20px);
      flex-basis: 200px;
    }
  }

  .testName {
    font-size: 1.4em;
  }

  .meterText {
    font-size: 2.5em;
    margin-top: 25px;

    &:empty:before {
      content: "0.00";
    }
  }

  .unit {
    margin-top: 5px;
  }

  #ipArea {
    margin-top: 20px;
  }
}

#tests {
  .control-group {
    display: flex;
    justify-content: center;
  }

  .input {
    background-color: #ffffff;
    border: 1px solid #cccccc;
    border-radius: 4px;
    color: #555555;
    line-height: 20px;
    height: 30px;
    padding: 4px 6px;
    transition: border linear 0.2s, box-shadow linear 0.2s;
  }

  .input-host {
    flex-basis: 210px;

    &:focus {
      border-color: rgba(82, 168, 236, 0.8);
      outline: thin dotted #333;
      box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 8px rgba(82, 168, 236, 0.6);
    }
  }

  .input-cmd {
    flex-basis: 150px;
    margin-left: 5px;

    &:focus {
      outline: thin dotted #333;
      outline-offset: -2px;
    }
  }

  .control-group.error .input-host {
    color: #bd4247;
    border-color: #bd4247;
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
  }
}

#results {
  .legend {
    display: block;
    margin-top: 20px;
    padding: 0 0 12px;
    width: 100%;
    border: 0;
    border-bottom: 1px solid #e5e5e5;
    font-weight: bold;
    font-size: 1.5rem;
  }

  #response {
    display: block;
    padding: 9.5px;
    margin: 20px 0 10px;
    font-size: .93rem;
    line-height: 20px;
    background-color: #f5f5f5;
    border: 1px solid rgba(0, 0, 0, 0.15);
    border-radius: 4px;
    overflow: auto;
    white-space: pre;
    word-break: break-all;
    word-wrap: normal;
  }
}

#startStopBtn {
  margin: 0 auto 25px;
}

#startStopBtn2 {
  margin: 35px auto 0;
}

#startStopBtn:before {
  content: "Протестировать";
}

#startStopBtn.running:before {
  content: "Тестируем";
}

#startStopBtn2:before {
  content: "Проверить";
}

#startStopBtn2.running:before {
  content: "Проверяем";
}
